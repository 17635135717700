import { Box, Button, Spinner, Text } from "@chakra-ui/react";
import { useState } from "react";
import { DuckTokenIcon, ToDoIcon } from "../../../theme/components/icon";
import { useMutation, useQuery } from "react-query";
import { APIClient } from "api";
import { useAppContext } from "contexts";
import { CiSaveDown2 } from "react-icons/ci";
import { formatTokenAmount } from "utils";
import toast from "react-hot-toast";
import { format } from "utils/datetime";
import scoreConfig from "../../../utils/scoreConfig.json";
const TransactionTag = ({ tx, isLast, refetchTask }) => {
  const { userToken, userInfo, fetchUserTaskList, userTaskList, fetchUser } =
    useAppContext();
  const claimTransactionReward = async () => {
    try {
      const hash = tx?.hash;
      const resp = await APIClient.claimTransactions(userToken, hash);
      if (resp?.success == true) {
        toast.success("Claimed successfully! Your point is updated");
        fetchUser();
        refetchTask();
      }
    } catch (error) {
      console.log(error);
    }
  };
  const { isLoading, mutate } = useMutation(async () => {
    return await claimTransactionReward();
  });
  const txTime = new Date(tx?.transaction_time * 1000);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: "10px",
        px: "10px",
        py: "4px",
        borderRadius: "40px",
        border: "2px solid rgba(26, 6, 179, 0.40)",
        background:
          "linear-gradient(180deg, rgba(0, 0, 103, 0.10) 0%, rgba(26, 6, 179, 0.00) 100%)",
        mb: isLast ? 0 : "10px",
        fontSize: "14px",
        fontWeight: "700",
      }}
      key={tx?.hash}
    >
      <Box
        sx={{
          flex: 1,
          display: "flex",
          alignItems: "center",
          gap: "6px",
          fontSize: "12px",
          userSelect: "none",
        }}
      >
        <CiSaveDown2 size={24} />
        <Box>
          Deposit {formatTokenAmount(tx?.amount)}TON
          <Text
            sx={{
              fontSize: "12px",
              color: "gray",
            }}
          >
            {format(txTime, "MMM Do YYYY")}
          </Text>
        </Box>
      </Box>
      {tx?.is_sync == false && (
        <Button
          size="sm"
          onClick={() => mutate()}
          gap="4px"
          isLoading={isLoading}
        >
          Claim <DuckTokenIcon width="18px" height="18px" />
        </Button>
      )}
    </Box>
  );
};
const TransactionPage = () => {
  const { userToken, userInfo, fetchUserTaskList, userTaskList, fetchUser } =
    useAppContext();
  const [tabIndex, setTabIndex] = useState(0);
  const [transactions, setTransactions] = useState([]);

  //   useEffect(() => {
  //     fetchUserTaskList();
  //   }, []);

  //   const taskList = useMemo(() => {
  //     if (tabIndex == 0) {
  //       return userTaskList?.filter(
  //         (e) => e?.is_finished == false || e?.task_code == "make_ton_transaction"
  //       );
  //     } else
  //       return userTaskList?.filter(
  //         (e) => e?.is_finished == true && e?.task_code != "make_ton_transaction"
  //       );
  //   }, [userTaskList, tabIndex]);
  const { isLoading, refetch } = useQuery("transactions", async () => {
    try {
      const resp = await APIClient.transactions(userToken);
      if (resp?.success == true) {
        console.log("resp", resp?.transactions);
        setTransactions(resp?.transactions);
      }
    } catch (error) {
      console.log(error);
    }
  });
  const filteredTransactions = transactions
    ?.filter((e) => +e?.amount >= scoreConfig.depositTaskAmount * 10 ** 9)
    ?.sort((a, b) => a.is_sync - b.is_sync);
  return (
    <Box
      sx={{
        w: "100%",
        h: "100%",
        display: "flex",
        flexDirection: "column",
        p: "32px",
        justifyContent: "center",
        bg: "#4ED4DD",
      }}
    >
      <Box
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          bg: "linear-gradient(270deg, #FFFDA4 0%, #FFFED9 49.67%, #FFFDA4 100%)",
          borderRadius: "40px",
        }}
      >
        <Box
          h="6vh"
          sx={{
            borderRadius: "40px",
            background: "#00006D",
            border: "2px solid gray",
            boxShadow:
              "0px -10px 30px 0px #1A06B3 inset, 0px 10px 20px 0px rgba(0, 0, 0, 0.20)",
            display: "flex",
            w: "calc(100% + 20px)",
            marginLeft: "-10px",
            marginTop: "-10px",
          }}
        >
          {[
            {
              icon: <ToDoIcon w="3vh" />,
              name: "Reward center",
            },
          ].map((e, index) => {
            const isActive = index == tabIndex;
            return (
              <Box
                sx={{
                  display: "flex",
                  flex: 1,
                  opacity: isActive ? 1 : 0.6,
                  boxShadow: isActive
                    ? "inset 0px -10px 30px 0px #1A06B3"
                    : null,
                  borderRadius: "40px",
                  transition: "all 0.2s ease-in-out",
                }}
                onClick={() => {
                  setTabIndex(index);
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flex: 1,
                    alignItems: "center",
                    gap: "10px",
                    justifyContent: "center",
                    borderRadius: "40px",
                    // border: "2px solid rgba(26, 6, 179, 0.20)",
                    background: isActive
                      ? "linear-gradient(264deg, #325EB6 7.02%, #3056B5 60.71%, #2225B4 94.59%)"
                      : null,
                    p: "10px",
                    transition: "all 0.2s ease-in-out",
                  }}
                >
                  {e?.icon}
                  <Text
                    userSelect="none"
                    sx={{
                      color: "#FFF",
                      textShadow: "0px 3px 3px #162B8A",
                      fontSize: "16px",
                      fontWeight: "700",
                      lineHeight: "normal",
                    }}
                  >
                    {e?.name}
                  </Text>
                </Box>
              </Box>
            );
          })}
        </Box>
        <Box
          maxH="55vh"
          overflowY="scroll"
          sx={{
            flex: 1,
            p: "10px",
            overflowY: "scroll",
          }}
        >
          {isLoading ? (
            <Box
              sx={{
                display: "flex",
                flex: 1,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Spinner />
            </Box>
          ) : (
            <>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  flex: 1,
                }}
              >
                <Text sx={{ fontSize: "12px" }}>
                  <Spinner w="12px" h="12px" mr="4px" /> Syncing in progress...
                </Text>
                <Text sx={{ fontSize: "12px", mb: "8px" }}>
                  Transaction history take less than 2 hours to update
                </Text>
              </Box>
              {filteredTransactions?.map((tx, index) => {
                return (
                  <TransactionTag
                    tx={tx}
                    refetchTask={refetch}
                    isLast={index == transactions?.length - 1}
                    key={`${tx?.hash}-${index}`}
                  />
                );
              })}
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default TransactionPage;
