import { Box, Button, Text } from "@chakra-ui/react";
import { useAppContext } from "contexts";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";

const YoutubePage = () => {
  const { userToken, userInfo, fetchUserTaskList, userTaskList, stableSize } =
    useAppContext();
  const history = useHistory();
  const location = useLocation();
  const [isEnableBack, setIsEnableBack] = useState(false);
  const { pathname } = location;
  const taskID = pathname.replace("/youtubevideo/", "");
  console.log(userTaskList);
  const task = userTaskList?.find((task) => task?.task_code == taskID);
  useEffect(() => {
    setTimeout(() => {
      setIsEnableBack(true);
    }, 20000);
  });
  return (
    <Box
      sx={{
        display: "flex",
        w: "100%",
        h: "100%",
        bg: "rgba(0,0,0,0.4)",
        py: "20px",
        flexDirection: "column",
      }}
    >
      <Button
        size="sm"
        sx={{
          px: "20px",
          ml: "10px",
        }}
        isLoading={!isEnableBack}
        alignSelf="flex-start"
        onClick={() => history.push(`/tasks`)}
      >
        Back
      </Button>
      <Box
        sx={{
          display: "flex",
          flex: 1,
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Text sx={{ fontWeight: "bold" }}>Watch this video</Text>
        <iframe
          width={stableSize?.width}
          height={(stableSize?.width * 315) / 560 + 120}
        //   src="https://www.youtube.com/embed/pC6LcyTN2Ok?si=DWRQEcCLlRTrI-a_&autoplay=1&mute=1"
          src="https://www.youtube.com/embed/pC6LcyTN2Ok?si=DWRQEcCLlRTrI-a_"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; web-share"
          referrerpolicy="strict-origin-when-cross-origin"
          allowfullscreen
          style={{ objectFit: "contain" }}
        ></iframe>
      </Box>
    </Box>
  );
};
export default YoutubePage;
