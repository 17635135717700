import { Box, Text } from "@chakra-ui/react";
const CampaignPage = () => {
  return (
    <Box
      sx={{
        p: "40px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Text
        sx={{
          fontSize: "24px",
          fontWeight: "bold",
        }}
      >
        CAMPAIGN
      </Text>
    </Box>
  );
};
export default CampaignPage;
