import {
  Box,
  Button,
  Center,
  Flex,
  Spinner,
  Square,
  Text,
} from "@chakra-ui/react";
import { useAppContext } from "../../contexts";
import { useEffect, useMemo } from "react";
import { getAgeMul, isAdmin, roundDown } from "../../utils";
import { SpriteAnimator } from "react-sprite-animator";
import ScoreTag from "../home/score";
import { useHistory } from "react-router-dom";
import { useDuckImage } from "hooks/useDuckImage";
const ScorePage = () => {
  const { currentPoint, fetchUser, userInfo } = useAppContext();
  const history = useHistory();

  useEffect(() => {
    fetchUser();
  }, []);

  const isUserAdmin = useMemo(() => {
    if (userInfo) {
      return isAdmin(userInfo?.id);
    }
  }, [userInfo]);

  const { duckUrl, loadDuckImage } = useDuckImage(userInfo);

  return (
    <>
      <Box h="28vh" />
      <Box
        w="100%"
        h="100%"
        bg="#4ED4DD"
        display="flex"
        position="relative"
        flexDirection="column"
      >
        <Box
          top="-26vh"
          w="100%"
          h="100%"
          display="flex"
          position="absolute"
          flexDirection="column"
        >
          <ScoreTag currentPoint={currentPoint} />

          <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            {loadDuckImage ? (
              <Center minH="230px" w="full" h="full">
                <Spinner size="xl" speed="0.25s" />
              </Center>
            ) : (
              <Square
                id="my-duck"
                transform="translateX(-2vh)"
                size="280px"
                overflow="hidden"
                style={{
                  userSelect: "none",
                  userDrag: "none",
                  draggable: "false",
                }}
              >
                <SpriteAnimator
                  style={{
                    userSelect: "none",
                    userDrag: "none",
                    draggable: false,
                    background: "red",
                  }}
                  scale={1.5}
                  loop={true}
                  width={512}
                  height={512}
                  fps={24}
                  // IDLE
                  startFrame={0}
                  frameCount={1}
                  shouldAnimate={true}
                  sprite={duckUrl}
                />
              </Square>
            )}
          </Flex>
          <Box
            sx={{
              marginTop: "-20px",
              display: "flex",
              flexDirection: "column",
              // alignItems: "center",\
              px: "40px",
              // overflow: "auto",
            }}
          >
            {isUserAdmin && (
              <Button
                mb="4px"
                size="sm"
                onClick={() => {
                  history.push("/admin");
                }}
              >
                ADMIN
              </Button>
            )}
            <Box
              sx={{
                borderRadius: "30px",
                background:
                  "linear-gradient(270deg, rgba(255, 255, 255, 0.00) 0%, rgba(255, 255, 255, 0.60) 50%, rgba(255, 255, 255, 0.00) 100%), #FFFDA4",
                boxShadow: "0px 10px 20px 0px rgba(0, 0, 0, 0.20)",
                display: "flex",
                px: "20px",
                py: "20px",
                flexDirection: "column",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flex: 1,
                }}
              >
                <Box sx={{ flex: 1 }}>
                  <Text
                    userSelect="none"
                    sx={{
                      color: "#00006D",
                      fontSize: "16px",
                      fontWeight: "300",
                      lineHeight: "normal",
                    }}
                  >
                    Premium account
                  </Text>
                  <Text
                    userSelect="none"
                    sx={{
                      color: "#00006D",
                      fontSize: "16px",
                      fontWeight: "300",
                      lineHeight: "normal",
                    }}
                  >
                    {userInfo?.age}-years-old account
                  </Text>
                  <Text
                    userSelect="none"
                    sx={{
                      color: "#00006D",
                      fontSize: "16px",
                      fontWeight: "300",
                      lineHeight: "normal",
                    }}
                  >
                    Invited {userInfo?.refer_count} friends
                  </Text>
                  <Text
                    userSelect="none"
                    sx={{
                      color: "#00006D",
                      fontSize: "16px",
                      fontWeight: "300",
                      lineHeight: "normal",
                    }}
                  >
                     Levels {userInfo?.level + 1}
                  </Text>
                  <Text
                    userSelect="none"
                    sx={{
                      color: "#00006D",
                      fontSize: "16px",
                      fontWeight: "300",
                      lineHeight: "normal",
                    }}
                  >
                    Boost
                  </Text>
                </Box>
                <Box
                  sx={{
                    textAlign: "right",
                  }}
                >
                  <Text
                    userSelect="none"
                    sx={{
                      color: "#00006D",
                      textAlign: "right",
                      fontSize: "16px",
                      fontWeight: "700",
                      lineHeight: "normal",
                    }}
                  >
                    {userInfo?.is_premium ? "5%" : "0%"}
                  </Text>
                  <Text
                    userSelect="none"
                    sx={{
                      color: "#00006D",
                      textAlign: "right",
                      fontSize: "16px",
                      fontWeight: "700",
                      lineHeight: "normal",
                    }}
                  >
                    {roundDown(getAgeMul(userInfo?.age) * 100, 2)}%
                  </Text>
                  <Text
                    userSelect="none"
                    sx={{
                      color: "#00006D",
                      textAlign: "right",
                      fontSize: "16px",
                      fontWeight: "700",
                      lineHeight: "normal",
                    }}
                  >
                    {roundDown(userInfo?.ref_mul_rate * 100, 2)}%
                  </Text>
                  <Text
                    userSelect="none"
                    sx={{
                      color: "#00006D",
                      textAlign: "right",
                      fontSize: "16px",
                      fontWeight: "700",
                      lineHeight: "normal",
                    }}
                  >
                    {roundDown(userInfo?.level_mul_rate * 100, 2)}%
                  </Text>
                  <Text
                    userSelect="none"
                    sx={{
                      color: "#00006D",
                      textAlign: "right",
                      fontSize: "16px",
                      fontWeight: "700",
                      lineHeight: "normal",
                    }}
                  >
                    --
                  </Text>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  mt: "12px",
                  alignItems: "center",
                }}
              >
                <Box>
                  <Text
                    userSelect="none"
                    sx={{
                      color: "#1A06B3",
                      textShadow: "0px 3px 3px rgba(0, 0, 0, 0.20)",
                      fontSize: "20px",
                      fontWeight: "700",
                      lineHeight: "normal",
                    }}
                  >
                    TOTAL
                  </Text>
                  <Text
                    userSelect="none"
                    sx={{
                      color: "#1A06B3",
                      textShadow: "0px 3px 3px rgba(0, 0, 0, 0.20)",
                      fontSize: "20px",
                      fontWeight: "700",
                      lineHeight: "normal",
                    }}
                  >
                    BOOST
                  </Text>
                </Box>
                <Button>
                  +{roundDown((userInfo?.mul_rate - 1) * 100, 2)}%
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default ScorePage;
