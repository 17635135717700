import { Box, Button, Image as ImageChakra, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useAppContext } from "../../contexts";
import { useHistory } from "react-router-dom";
import { roundDown } from "utils";
import { DuckTokenIcon } from "theme/components/icon";
import scoreConfig from "../../utils/scoreConfig.json";
import { PREVIOUS_REFER_POINT_KEY } from "constants";
import { formatNumDynDecimal } from "utils";
import { APIClient } from "api";
import { useMutation } from "react-query";
import { useTelegram } from "contexts/telegram";

const StartPage = () => {
  const tgWebApp = useTelegram();
  const {
    isLoadingUser,
    userInfo,
    isValidUser,
    requireRegisted,
    currentPoint,
    stableSize,
    previousReferPoint,
    userToken,
  } = useAppContext();
  const history = useHistory();
  const [tab, setTab] = useState(0);
  const diffReferPoint = +userInfo?.refer_point - +previousReferPoint;
  // useEffect(() => {
  //   console.log(userInfo && userToken);
  //   if (userInfo && userToken) {
  //     (async () => {
  //       const resp = await APIClient.checkin(userToken);
  //       if (resp?.success == true) {

  //       }
  //     })();
  //   }
  // }, [userToken, userInfo]);
  const checkinMutation = useMutation(async () => {
    try {
      const resp = await APIClient.checkin(userToken);
      if (resp?.success == true) {
        history.push("/checkin?streak=" + resp?.streak);
        localStorage.setItem(
          PREVIOUS_REFER_POINT_KEY,
          userInfo?.refer_point || 0
        );
      } else {
        history.push("/app");
        localStorage.setItem(
          PREVIOUS_REFER_POINT_KEY,
          userInfo?.refer_point || 0
        );
      }
    } catch (error) {
      history.push("/app");
      localStorage.setItem(
        PREVIOUS_REFER_POINT_KEY,
        userInfo?.refer_point || 0
      );
    }
  });
  const startParam = tgWebApp?.WebApp?.initDataUnsafe?.start_param;
  const paramInviteUrl = `https://t.me/${process.env.REACT_APP_TELEGRAM_ID}?start=${startParam}`;
  if (requireRegisted) {
    return (
      <Box
        w={`${stableSize?.width}px`}
        maxW={`${stableSize?.width}px`}
        h={`${stableSize?.height}px`}
        display="flex"
        overflow="hidden"
      >
        <Box
          w={`${stableSize?.width}px`}
          h={`${stableSize?.height}px`}
          display="flex"
          flexDirection="column"
          sx={{
            position: "absolute",
            top: 0,
            left: tab == 0 ? 0 : `-${stableSize?.width}px`,
            transition: "all 0.2s ease-in-out",
            p: "20px",
          }}
        >
          <Box
            display="flex"
            flexDirection="column"
            flex={1}
            sx={{ justifyContent: "center", alignItems: "center" }}
          >
            <Button>Welcome</Button>
            <ImageChakra
              w={`${stableSize?.width / 1.2}px`}
              h={`${stableSize?.width / 1.2}px`}
              src="assets/welcome-sticker-01.png"
              objectFit="contain"
            />
            <Box display="flex" alignItems="center" gap="4px">
              <Text
                sx={{
                  color: "#00006D",
                  textAlign: "center",
                  fontSize: "24px",
                  fontWeight: "700",
                  lineHeight: "normal",
                }}
              >
                Tap and earn
              </Text>
              <Text
                sx={{
                  color: "#00006D",
                  textAlign: "center",
                  fontSize: "24px",
                  fontWeight: "700",
                  lineHeight: "normal",
                }}
              >
                <DuckTokenIcon width="32px" height="32px" /> daily!
              </Text>
            </Box>
          </Box>
          <Button
            onClick={() => {
              if (startParam) tgWebApp.openTelegramLink(paramInviteUrl);
              setTab(1);
            }}
          >
            Next
          </Button>
        </Box>
        <Box
          w={`${stableSize?.width}px`}
          h={`${stableSize?.height}px`}
          flexDirection="column"
          display={tab == 1 ? "block" : "none"}
          sx={{
            position: "absolute",
            top: 0,
            left:
              tab < 1
                ? `${stableSize?.width}px`
                : tab == 1
                ? 0
                : `-${stableSize?.width}px`,
            transition: "all 0.2s ease-in-out",
            p: "20px",
          }}
        >
          <Box w="100%" h="100%" display="flex" flexDirection="column">
            <Box
              display="flex"
              flexDirection="column"
              flex={1}
              sx={{ justifyContent: "center", alignItems: "center" }}
            >
              <ImageChakra
                w={`${stableSize?.width / 1.5}px`}
                h={`${stableSize?.width / 1.5}px`}
                src="assets/welcome-sticker-02.png"
                objectFit="contain"
              />
              <Text
                sx={{
                  color: "#00006D",
                  textAlign: "center",
                  fontSize: "24px",
                  fontWeight: "700",
                  lineHeight: "normal",
                }}
              >
                Invite Friends
              </Text>
              <ImageChakra
                w={`${stableSize?.width / 2.2}px`}
                h={`${((stableSize?.width / 2.2) * 90) / 250}px`}
                mt="8px"
                src="assets/intro-feature-1.png"
                objectFit="contain"
              />
              <Text
                sx={{
                  color: "#00006D",
                  textAlign: "center",
                  fontSize: "24px",
                  fontWeight: "700",
                  lineHeight: "normal",
                  mt: "10px",
                }}
              >
                and get <DuckTokenIcon width="32px" height="32px" />
              </Text>
            </Box>

            <Button onClick={() => setTab(2)}>Next</Button>
          </Box>
        </Box>
        <Box
          w={`${stableSize?.width}px`}
          h={`${stableSize?.height}px`}
          display={tab == 2 ? "block" : "none"}
          flexDirection="column"
          sx={{
            position: "absolute",
            top: 0,
            left:
              tab < 2
                ? `${stableSize?.width}px`
                : tab == 2
                ? 0
                : `-${stableSize?.width}px`,
            transition: "all 0.2s ease-in-out",
            p: "20px",
          }}
        >
          <Box w="100%" h="100%" display="flex" flexDirection="column">
            <Box
              display="flex"
              flexDirection="column"
              flex={1}
              sx={{ justifyContent: "center", alignItems: "center" }}
            >
              <ImageChakra
                w={`${stableSize?.width / 1.5}px`}
                h={`${stableSize?.width / 1.5}px`}
                src="assets/welcome-sticker-02.png"
                objectFit="contain"
              />
              <Text
                sx={{
                  color: "#00006D",
                  textAlign: "center",
                  fontSize: "24px",
                  fontWeight: "700",
                  lineHeight: "normal",
                }}
              >
                Complete Tasks
              </Text>
              <ImageChakra
                w={`${stableSize?.width / 2.2}px`}
                h={`${((stableSize?.width / 2.2) * 90) / 250}px`}
                mt="8px"
                src="assets/intro-feature-2.png"
                objectFit="contain"
              />
              <Text
                sx={{
                  color: "#00006D",
                  textAlign: "center",
                  fontSize: "24px",
                  fontWeight: "700",
                  lineHeight: "normal",
                  mt: "10px",
                }}
              >
                and get <DuckTokenIcon width="32px" height="32px" />
              </Text>
            </Box>

            <Button onClick={() => setTab(3)}>Next</Button>
          </Box>
        </Box>
        <Box
          w={`${stableSize?.width}px`}
          h={`${stableSize?.height}px`}
          display={tab == 3 ? "block" : "none"}
          flexDirection="column"
          sx={{
            position: "absolute",
            top: 0,
            left:
              tab < 3
                ? `${stableSize?.width}px`
                : tab == 3
                ? 0
                : `-${stableSize?.width}px`,
            transition: "all 0.2s ease-in-out",
          }}
        >
          <Box w="100%" h="100%" display="flex" flexDirection="column">
            <Box
              display="flex"
              flex={1}
              flexDirection="column"
              sx={{
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <ImageChakra
                w={`${stableSize?.width / 1.4}px`}
                h={`${stableSize?.width / 1.4}px`}
                src="assets/Logo2.png"
                objectFit="contain"
              />
              <Text
                userSelect="none"
                sx={{
                  color: "#00006D",
                  textAlign: "center",
                  fontSize: "24px",
                  fontWeight: "700",
                  lineHeight: "normal",
                  mt: "20px",
                }}
              >
                Congrats!
              </Text>
              <Text
                userSelect="none"
                sx={{
                  color: "#00006D",
                  textAlign: "center",
                  fontSize: "24px",
                  fontWeight: "700",
                  lineHeight: "normal",
                }}
              >
                You've earned: {roundDown(currentPoint, 2)} points
              </Text>
              <Box
                mt="16px"
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  w: `${stableSize?.width - 40}px`,
                }}
              >
                <Text
                  userSelect="none"
                  sx={{
                    color: "#00006D",
                    textAlign: "center",
                    fontSize: "16px",
                    fontWeight: "700",
                    lineHeight: "normal",
                  }}
                >
                  Premium Account
                </Text>
                <Text userSelect="none" fontWeight="bold">
                  {isLoadingUser ? "Loading" : userInfo?.is_premium ? 300 : 0}{" "}
                  <DuckTokenIcon width="24px" height="24px" />
                </Text>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  w: `${stableSize?.width - 40}px`,
                }}
              >
                <Text
                  userSelect="none"
                  sx={{
                    color: "#00006D",
                    textAlign: "center",
                    fontSize: "16px",
                    fontWeight: "700",
                    lineHeight: "normal",
                  }}
                >
                  Account Age ({userInfo?.age} years)
                </Text>
                <Text userSelect="none" fontWeight="bold">
                  {
                    scoreConfig.ageConfig.find((e) => e.age == userInfo?.age)
                      ?.score
                  }{" "}
                  <DuckTokenIcon width="24px" height="24px" />
                </Text>
              </Box>
            </Box>
            <Button
              mb="20px"
              isDisabled={!isValidUser}
              isLoading={isLoadingUser}
              onClick={() => history.push("/app")}
              sx={{
                mx: "20px",
                fontSize: stableSize?.width < 400 ? "24px" : "32px",
              }}
            >
              Play
            </Button>
          </Box>
        </Box>
      </Box>
    );
  }

  return (
    <Box w="100%" h="100%" display="flex" flexDirection="column">
      <Box display="flex" flex={1} flexDirection="column">
        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              borderRadius: "40px",
              border: "2px solid rgba(26, 6, 179, 0.40)",
              background: "#366CB6",
              boxShadow:
                "0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px -10px 15px 0px #1A06B3 inset",
              color: "#FFF",
              fontSize: stableSize?.width < 400 ? "20px" : "32px",
              fontWeight: "700",
              lineHeight: "normal",
              px: "10px",
              py: "4px",
            }}
          >
            <Text userSelect="none">Welcome</Text>
          </Box>
          <ImageChakra
            w={`${stableSize?.width / 1}px`}
            h={`${stableSize?.width / 1}px`}
            src="assets/welcome-sticker-03.png"
            objectFit="contain"
          />
          <Box
            sx={{
              border: "solid 2.4px #2FA8F5",
              px: "24px",
              py: "8px",
              borderRadius: "8px",
              fontWeight: "bold",
              background: "linear-gradient(90deg, #0685CB 0%, #3161A8 100%)",
              backgroundClip: "text",
              gap: "8px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Text>Convert</Text> <DuckTokenIcon width="32px" height="32px" />{" "}
            <Text>to token soon</Text>
          </Box>
        </Box>
      </Box>
      {diffReferPoint > 0 ? (
        <Text
          userSelect="none"
          sx={{
            color: "#00006D",
            textAlign: "center",
            fontSize: "20px",
            fontWeight: "700",
            lineHeight: "normal",
            mx: "20px",
          }}
        >
          You've just earned{" "}
          {formatNumDynDecimal(roundDown(diffReferPoint, 2), 2)}{" "}
          <DuckTokenIcon width="32px" height="32px" /> from your referees
        </Text>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row",
            pb: "20px",
          }}
        >
          <Text
            userSelect="none"
            sx={{
              color: "#00006D",
              textAlign: "center",
              fontSize: "20px",
              fontWeight: "700",
              lineHeight: "normal",
            }}
          >
            Tap and earn
          </Text>
          <Text
            userSelect="none"
            sx={{
              color: "#00006D",
              textAlign: "center",
              fontSize: "20px",
              fontWeight: "700",
              lineHeight: "normal",
            }}
          >
            <DuckTokenIcon width="28px" height="28px" /> daily!
          </Text>
        </Box>
      )}
      <Button
        mb="20px"
        isDisabled={!isValidUser}
        isLoading={isLoadingUser || checkinMutation.isLoading}
        onClick={async () => checkinMutation.mutate()}
        sx={{ mx: "20px", fontSize: stableSize?.width < 400 ? "24px" : "32px" }}
      >
        Play
      </Button>
    </Box>
  );
};
export default StartPage;
