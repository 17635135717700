import { Box, Button, Text } from "@chakra-ui/react";
import { useTonAddress, useTonConnectUI } from "@tonconnect/ui-react";
import { useState } from "react";
import toast from "react-hot-toast";
import { useMutation } from "react-query";
import { useHistory } from "react-router-dom";
import { formatToK } from "utils";
import { APIClient } from "../../api";
import { useAppContext } from "../../contexts";
import { MASTER_WALLET_ADDRESS } from "constants";
import { useTelegram } from "contexts/telegram";
import { IoIosGift } from "react-icons/io";
import { BiSolidPlusCircle } from "react-icons/bi";
import { FaAngleRight } from "react-icons/fa";

const DepositTag = ({ data, index }) => {
  const history = useHistory();

  const walletAddress = useTonAddress();
  const rawtAddress = useTonAddress(false);
  console.log("rawtAddress", rawtAddress);
  const { tgWebApp } = useTelegram();
  const {
    userToken,
    userInfo,
    userTaskList,
    fetchUserTaskList,
    fetchUser,
    isConnectWalletTaskClicked,
    setIsConnectWalletTaskClicked,
  } = useAppContext();
  const [tonConnectUI, setOptions] = useTonConnectUI();
  const myTransaction = {
    validUntil: Math.floor(Date.now() / 1000) + 120, // 60 sec
    messages: [
      {
        // address: "UQB-jjPT265dH6QKWtd8RUDSNDVqZbqSQcrOMYRSyl5otdnx",
        address: MASTER_WALLET_ADDRESS.DEPOSIT_TASK,
        amount: (+data?.target * 10 ** 9).toString(),
      },
    ],
  };
  const handleDeposit = async () => {
    try {
      console.log(
        tonConnectUI.account.address,
        tonConnectUI.account.chain,
        tonConnectUI.account.publicKey,
        tonConnectUI.account.walletStateInit
      );
      const resp = await tonConnectUI.sendTransaction(myTransaction);
      if (resp?.boc) {
        toast.success("Transaction Success, please check after 2 minutes");
      } else {
        toast.error("Transaction Failed");
      }
    } catch (error) {
      // toast.error("Something wrong", error);
    }
  };

  const [isClicked, setIsClicked] = useState(false);
  const doTask = async (taskData) => {
    try {
      const { task_code, name, target } = taskData;
      if (["make_ton_transaction"].includes(task_code)) {
        handleDeposit();
      }
    } catch (error) {
      console.log(error);
      toast.error(error);
    }
  };
  const doTaskMutation = useMutation(async (taskData) => {
    setIsClicked(true);
    await doTask(taskData);
  });
  // const doCheckMutation = useMutation(async (taskData) => {
  //   const { task_code, name, target } = taskData;
  //   try {
  //     if (["make_ton_transaction"].includes(task_code)) {
  //       const resp2 = await APIClient.verifyTask(userToken, task_code, target);
  //       if (resp2?.success == true)
  //         toast.success("Success, your point was updated");
  //       else toast(resp2?.message);
  //       await fetchUserTaskList();
  //       await fetchUser();
  //     }
  //   } catch (error) {
  //     console.log(error);
  //     toast.error("Something Wrong", error);
  //   }
  // });
  return (
    <Box
      sx={{
        display: "flex",
        borderRadius: "8px",
        border: "2px solid rgba(26, 6, 179, 0.40)",
        background: "linear-gradient(180deg, #F2F8FF 0%, #FEFAA5 51.43%)",
        mb: index < userTaskList?.length ? "10px" : 0,
        pr: "10px",
        alignItems: "center",
        ml: "20px",
        h: "48px",
        position: "relative",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          w: "100%",
          h: "24px",
          bottom: 0,
          left: 0,
          background: "linear-gradient(0deg, #F6F061 16.67%, #E9E592 100%)",
          borderBottomRadius: "6px",
        }}
      ></Box>
      <Box
        sx={{
          borderRadius: "40px",
          // background: "#366CB6",
          // boxShadow: "0px -5px 10px 0px #1A06B3 inset",
          w: "60px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          border: "2px solid rgba(26, 6, 179, 0.40)",
          ml: "-20px",
          py: "4px",
          background:
            "linear-gradient(180deg, #305EAF 0%, #131191 47.7%, #305EAF 100%)",
          gap: "2px",
          zIndex: 1,
        }}
      >
        <BiSolidPlusCircle color="#FFF" />
        <Text
          userSelect="none"
          sx={{
            color: "#FFF",
            fontSize: "16px",
            fontWeight: "700",
            lineHeight: "normal",
            alignSelf: "center",
          }}
        >
          {formatToK(data?.point)}
        </Text>
      </Box>
      <Box
        sx={{
          display: "flex",
          flex: 1,
          pl: "4px",
          alignItems: "flex-start",
          flexDirection: "column",
          justifyContent: "center",
          zIndex: 1,
        }}
        onClick={() => {
          if (walletAddress) {
            doTaskMutation.mutate(data);
          } else {
            try {
              tgWebApp.showPopup(
                {
                  title: "Wallet Required",
                  message: "Please Connect your wallet first",
                  buttons: [
                    {
                      id: "connect-op-id",
                      type: "default",
                      text: "Connect",
                    },
                    {
                      id: "cancel-op-id",
                      type: "default",
                      text: "Cancel",
                    },
                  ],
                },
                (buttonId) => {
                  if (buttonId === "connect-op-id") {
                    history.push("/level");
                  }
                }
              );
            } catch (error) {}
          }
        }}
      >
        <Text
          userSelect="none"
          sx={{
            color: "#00006D",
            fontSize: "12px",
            fontWeight: "700",
            lineHeight: "normal",
          }}
        >
          {/* {data?.name} */}
          Make TON strong again
        </Text>
      </Box>
      <Button
        size="sm"
        mr="4px"
        px="8px"
        // isLoading={doCheckMutation.isLoading}
        onClick={() => {
          if (walletAddress) {
            doTaskMutation.mutate(data);
          } else {
            try {
              tgWebApp.showPopup(
                {
                  title: "Wallet Required",
                  message: "Please Connect your wallet first",
                  buttons: [
                    {
                      id: "connect-op-id",
                      type: "default",
                      text: "Connect",
                    },
                    {
                      id: "cancel-op-id",
                      type: "default",
                      text: "Cancel",
                    },
                  ],
                },
                (buttonId) => {
                  if (buttonId === "connect-op-id") {
                    history.push("/level");
                  }
                }
              );
            } catch (error) {}
          }
        }}
      >
        <FaAngleRight />
      </Button>
      <Button
        size="sm"
        px="8px"
        // isLoading={doCheckMutation.isLoading}
        onClick={() => {
          history.push("/transactions");
          // doCheckMutation.mutate(data);
        }}
      >
        <IoIosGift />
      </Button>
    </Box>
  );
};

export default DepositTag;
