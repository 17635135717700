import { useTelegram } from "contexts/telegram";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";

const RedirectComponent = () => {
  // Get the query parameters from the URL
  const history = useHistory();
  const { tgWebApp } = useTelegram();
  const userID = history.location.pathname?.replace("/redirect/", "");
  useEffect(() => {
    if (userID) {
      console.log("User ID:", userID);
    } else {
      console.log("No User ID found in the URL");
    }

    const hasRedirected = localStorage.getItem("redirected");
    if (!hasRedirected) {
      tgWebApp.openTelegramLink(`https://t.me/AlephZeroAnnouncements`);
      //   window.location.href = "https://t.me/AlephZeroAnnouncements";
    }
    localStorage.setItem("redirected", "true");
  }, [userID]);

  return (
    <div>
      <p>Redirecting to AlephZero Announcements...</p>
    </div>
  );
};

export default RedirectComponent;
