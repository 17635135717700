import { Box, Button, Spinner, Text } from "@chakra-ui/react";
import { useState } from "react";
import { useQuery } from "react-query";
import { APIClient } from "../../api";
import { useAppContext } from "../../contexts";
import { formatNumDynDecimal, getNameHidden, roundDown } from "../../utils";
import { AiOutlineUser } from "react-icons/ai";
import { RefIcon, StarIcon } from "../../theme/components/icon";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const REF_INDEX = 1;
const POINT_INDEX = 0;
const LeaderboardAdmin = () => {
  const { userInfo, stableSize } = useAppContext();
  const [rankList, setRankList] = useState([]);
  const [tabIndex, setTabIndex] = useState(POINT_INDEX);
  const [myrank, setMyrank] = useState(null);
  const { isLoading } = useQuery(["fetch-ranking", tabIndex], async () => {
    if (tabIndex == 1) {
      const resp = await APIClient.fetchRankingRefAdmin();
      if (resp?.success == true) {
        setRankList(resp?.data?.list);
        setMyrank(resp?.data?.myRank);
      } else {
        setRankList([]);
        setMyrank(null);
      }
    } else if (tabIndex == 0) {
      const resp = await APIClient.fetchRankingPointAdmin();
      if (resp?.success == true) {
        setRankList(resp?.data?.list);
        setMyrank(resp?.data?.myRank);
      } else {
        setRankList([]);
        setMyrank(null);
      }
    }
  });

  const myRank = rankList?.find((e) => e?.id == userInfo?.id);

  const history = useHistory();

  return (
    <Box bg="white" p="4">
      <Button
        size="xs"
        mb="8px"
        role="button"
        onClick={() => history.push(`/admin`)}
      >
        Back
      </Button>
      <Box
        sx={{
          w: "100%",
          h: "100%",
          display: "flex",
          flexDirection: "column",
          p: stableSize?.width < 400 ? "20px" : "32px",
          justifyContent: "center",
          bg: "#4ED4DD",
        }}
      >
        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            bg: "linear-gradient(270deg, #FFFDA4 0%, #FFFED9 49.67%, #FFFDA4 100%)",
            borderRadius: "24px",
          }}
        >
          <Box
            h="6vh"
            sx={{
              borderRadius: "40px",
              background: "#00006D",
              border: "2px solid gray",
              boxShadow:
                "0px -10px 30px 0px #1A06B3 inset, 0px 10px 20px 0px rgba(0, 0, 0, 0.20)",
              display: "flex",
              w: "calc(100% + 20px)",
              marginLeft: "-10px",
              marginTop: "-10px",
            }}
          >
            {[
              {
                icon: <RefIcon w="3.5vh" />,
                name: "Leaderboard",
              },
              {
                icon: <StarIcon w="3.5vh" />,
                name: "by Ref",
              },
            ].map((e, tIndex) => {
              const isActive = tIndex == tabIndex;
              return (
                <Box
                  sx={{
                    display: "flex",
                    flex: 1,
                    opacity: isActive ? 1 : 0.6,
                    boxShadow: isActive
                      ? "inset 0px -10px 30px 0px #1A06B3"
                      : null,
                    borderRadius: "40px",
                    transition: "all 0.2s ease-in-out",
                  }}
                  onClick={() => {
                    setTabIndex(tIndex);
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flex: 1,
                      alignItems: "center",
                      gap: "10px",
                      justifyContent: "center",
                      borderRadius: "40px",
                      // border: "2px solid rgba(26, 6, 179, 0.20)",
                      background: isActive
                        ? "linear-gradient(264deg, #325EB6 7.02%, #3056B5 60.71%, #2225B4 94.59%)"
                        : null,
                      p: stableSize?.width < 400 ? "12px" : "14px",
                      transition: "all 0.2s ease-in-out",
                    }}
                  >
                    {e?.icon}
                    <Text
                      userSelect="none"
                      sx={{
                        color: "#FFF",
                        textShadow: "0px 3px 3px #162B8A",
                        fontSize: "16px",
                        fontWeight: "700",
                        lineHeight: "normal",
                      }}
                    >
                      {e?.name}
                    </Text>
                  </Box>
                </Box>
              );
            })}
          </Box>
          <Box
            sx={{
              // flex: 1,
              p: "10px",
              overflowY: "auto",
              h: "calc(100vh - 100px)",
            }}
          >
            {isLoading ? (
              <Box
                sx={{
                  flex: 1,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Spinner />
              </Box>
            ) : (
              <>
                <Box
                  sx={{
                    borderRadius: "20px",
                    background:
                      "linear-gradient(90deg, rgba(54, 108, 182, 0.40) 0%, #366CB6 50%, rgba(54, 108, 182, 0.00) 100%)",
                    color: "#FFF",
                    fontSize: "12px",
                    fontWeight: "700",
                    lineHeight: "normal",
                    px: "50px",
                    py: "4px",
                    mb: "8px",
                    mt: "8px",
                  }}
                >
                  <Text userSelect="none">Top Rank</Text>
                </Box>
                {rankList?.map((e, index) => {
                  const isTop3 = index < 3;
                  return (
                    <Box
                      role="button"
                      onClick={() => history.push(`/admin/${e.id}`)}
                      sx={{
                        display: "flex",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          w: "28px",
                          h: "28px",
                          borderRadius: "28px",
                          bg: isTop3 ? "#FFFB49" : null,
                          marginRight: "10px",
                        }}
                      >
                        <Text
                          userSelect="none"
                          sx={{
                            color: "#00006D",
                            textAlign: "center",
                            fontSize: isTop3 ? "14px" : "12px",
                            fontWeight: "700",
                            lineHeight: "normal",
                          }}
                        >
                          {index + 1}
                        </Text>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flex: 1,
                          borderRadius: "40px",
                          border: "2px solid rgba(26, 6, 179, 0.40)",
                          background:
                            index == 0
                              ? "linear-gradient(180deg, rgba(255, 251, 73, 0.00) 0%, #FFFB49 100%)"
                              : index == 1
                              ? "linear-gradient(180deg, rgba(78, 212, 221, 0.00) 0%, #4ED4DD 100%)"
                              : index == 2
                              ? "linear-gradient(180deg, rgba(54, 108, 182, 0.00) 0%, #366CB6 100%)"
                              : "linear-gradient(180deg, rgba(0, 0, 103, 0.10) 0%, rgba(26, 6, 179, 0.00) 100%)",
                          mb: index < rankList?.length ? "10px" : 0,
                          boxShadow: "0px -5px 20px 0px #FFF",
                        }}
                      >
                        <Box
                          sx={{
                            borderRadius: "40px",
                            background: "#366CB6",
                            boxShadow: "0px -5px 10px 0px #1A06B3 inset",
                            w: "28px",
                            h: "28px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <AiOutlineUser color="#FFF" />
                          {/* <Image
                    src="assets/taskicon.png"
                    sx={{
                      w: "20px",
                      h: "20px",
                      filter: "drop-shadow(0px 5px 5px #0A0285)",
                      objectFit: "contain",
                    }}
                    _active={{}}
                  /> */}
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            flex: 1,
                            pl: "4px",
                            alignItems: "center",
                            // flexDirection: "column",
                          }}
                        >
                          <Text
                            userSelect="none"
                            sx={{
                              color: "#00006D",
                              fontSize: "12px",
                              fontWeight: "700",
                              lineHeight: "normal",
                              pl: "8px",
                              // textAlign: "center",
                            }}
                          >
                            {getNameHidden(e)}
                          </Text>
                        </Box>
                        <Box
                          sx={{
                            borderRadius: "40px",
                            background: "#366CB6",
                            boxShadow: "0px -10px 15px 0px #1A06B3 inset",
                            display: "flex",
                            alignItems: "center",
                            minW: "100px",
                            justifyContent: "center",
                          }}
                        >
                          <Text
                            userSelect="none"
                            sx={{
                              color: "#FFF",
                              fontSize: "12px",
                              fontWeight: "700",
                              lineHeight: "normal",
                              px: "8px",
                            }}
                          >
                            {tabIndex == REF_INDEX
                              ? formatNumDynDecimal(e?.refer_count, 0)
                              : formatNumDynDecimal(
                                  roundDown(e?.point + e?.refer_point, 2),
                                  2
                                )}
                          </Text>
                        </Box>
                      </Box>
                    </Box>
                  );
                })}
              </>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default LeaderboardAdmin;
