import {
  Box,
  Button,
  Card,
  Flex,
  Heading,
  HStack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import { APIClient } from "api";
import { useAppContext } from "contexts";
import { useEffect, useState } from "react";
import { roundDown } from "utils";
import { formatNumDynDecimal } from "utils";
import { useHistory } from "react-router-dom";
import { PieChart } from "pages/AdminPerUser";

const StatusAdminPage = () => {
  const history = useHistory();
  const { userToken, userInfo, stableSize } = useAppContext();
  const [data, setData] = useState(null);
  const [chartData, setChartData] = useState(null);

  useEffect(() => {
    (async () => {
      const resp2 = await APIClient.getUserStatus(userToken);
      if (resp2?.success) {
        console.log({ resp2 });
        const {
          pointRange,
          refCountRange,
          refPointRange,
          ageRange,
          levelRange,
        } = resp2;
        setData(resp2);
        setChartData({
          pointRange,
          refCountRange,
          refPointRange,
          ageRange,
          levelRange,
        });
      }
    })();
  }, [userToken]);

  return (
    <Box bg="white" p="4">
      <Button
        size="xs"
        mb="8px"
        role="button"
        onClick={() => history.push(`/admin`)}
      >
        Back
      </Button>
      <HStack py="12px">
        <Box w="50%">
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Text>Total user</Text>
            <Text>{data?.totalUser}</Text>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Text>Play point</Text>
            <Text>
              {formatNumDynDecimal(roundDown(data?.totalPoints, 2), 2)}
            </Text>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Text>ref point</Text>
            <Text>
              {formatNumDynDecimal(roundDown(data?.totalPointsRefer, 2), 2)}
            </Text>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Text>Total point</Text>
            <Text>{formatNumDynDecimal(roundDown(data?.total, 2), 2)}</Text>
          </Box>
        </Box>
        <Box w="50%">
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Text>Connect X</Text>
            <Text>{formatNumDynDecimal(roundDown(data?.counttw, 2), 2)}</Text>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Text>Follow X tweet</Text>
            <Text>
              {formatNumDynDecimal(roundDown(data?.countFollowTw, 2), 2)}
            </Text>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Text>Join Group tele</Text>
            <Text>
              {formatNumDynDecimal(roundDown(data?.countJoinGr, 2), 2)}
            </Text>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Text>Connnect Wallet</Text>
            <Text>
              {formatNumDynDecimal(roundDown(data?.countConnectWallet, 2), 2)}
            </Text>
          </Box>
        </Box>
      </HStack>
      <Wrap py="12px">
        {chartData &&
          Object.entries(chartData)?.map(([label, data]) => (
            <WrapItem w="440px">
              <Card variant={"filled"} p="8px" w="full" fontSize={"12px"}>
                <Heading mb="12px" as="h5" fontSize="18px" textAlign="center">
                  {label}
                </Heading>

                <Flex
                  w="full"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <PieChart data={data} />
                  <TableContainer>
                    <Table size="sm" variant="striped" colorScheme="teal">
                      <Thead>
                        <Tr>
                          <Th>Label</Th>
                          <Th isNumeric>Value</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {data?.map(({ label, value }) => (
                          <Tr>
                            <Td>{label}</Td>
                            <Td isNumeric>{value}</Td>
                          </Tr>
                        ))}
                      </Tbody>
                    </Table>
                  </TableContainer>
                </Flex>
              </Card>
            </WrapItem>
          ))}
      </Wrap>
    </Box>
  );
};

export default StatusAdminPage;
