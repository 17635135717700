import { Box, Button, Text, Image as ImageChakra } from "@chakra-ui/react";
import { useAppContext } from "contexts";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { DuckTokenIcon } from "theme/components/icon";

const CheckinPage = () => {
  const {
    isLoadingUser,
    userInfo,
    isValidUser,
    requireRegisted,
    currentPoint,
    stableSize,
    previousReferPoint,
    userToken,
  } = useAppContext();
  const history = useHistory();
  console.log(history);
  const queryParams = new URLSearchParams(history.location.search);
  const streak = queryParams.get("streak");

  return (
    <Box w="100%" h="100%" display="flex" flexDirection="column" px="10px">
      <Box
        sx={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <ImageChakra
          w={`${stableSize?.width / 1.4}px`}
          h={`${stableSize?.width / 1.4}px`}
          src="assets/welcome-sticker-01.png"
          objectFit="contain"
        />
        <Text sx={{ fontWeight: "bold", fontSize: "6vw" }}>
          Daily checkin streak
        </Text>
        <Text sx={{ fontWeight: "bold", fontSize: "10vw" }}>{streak}</Text>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "4px",
          }}
        >
          <Text sx={{ fontWeight: "bold", fontSize: "6vw" }}>
            Reward +{streak * 100}
          </Text>
          <Box>
            <DuckTokenIcon width="20px" height="20px" />
          </Box>
        </Box>
      </Box>
      <Text sx={{ textAlign: "center" }}>
        Comeback tomorrow for more reward
      </Text>
      <Button mb="20px" onClick={() => history.push("/app")}>
        Play
      </Button>
    </Box>
  );
};

export default CheckinPage;
